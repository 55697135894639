import '../inits/bootstrap.js';
import Vue from 'vue';
import { store } from '../inits/vue-store.js';
import toastHandler from '@/components/toast-handler/toast-handler.vue';

new Vue({
    el: '#toast-container',
    components: {
        toastHandler,
    },
    store,
});
